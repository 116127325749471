<template>
  <div class="customer-profiling-details-nickname-modal">
    <div class="customer-profiling-details-nickname-modal__frame">
      <div class="customer-profiling-details-nickname-modal__header">
        <div class="customer-profiling-details-nickname-modal__heading">
          Nickname
        </div>
      </div>
      <div class="customer-profiling-details-nickname-modal__form">
        <div class="customer-profiling-details-nickname-modal__form-group">
          <div class="customer-profiling-details-nickname-modal__form-label">
            Please insert nickname for this customer:
          </div>
          <TextInput
            v-model="localModelValue"
            small
            no-clear-button
          />
        </div>
      </div>
      <div class="customer-profiling-details-nickname-modal__footer">
        <Button
          variant="tertiary"
          @click="dismissChanges"
        >
          Dismiss
        </Button>
        <Button
          variant="primary"
          :disabled="isLocalModelValueInvalid"
          @click="saveChanges"
        >
          Save
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { trim } from 'lodash';
import { ref, computed } from 'vue';
import Button from '@/components/common/Button';
import TextInput from '@/components/common/TextInput';

export default {
  components: {
    Button,
    TextInput,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
    close: {},
  },
  setup(props, { emit }) {
    const localModelValue = ref(props.modelValue);

    const isLocalModelValueInvalid = computed(() => {
      const trimmedLength = trim(localModelValue.value || '')?.length;
      return (props.modelValue === localModelValue.value) || trimmedLength >= 100;
    });

    const dismissChanges = () => {
      emit('close');
    };
    const saveChanges = () => {
      if (isLocalModelValueInvalid.value) return;
      emit('update:modelValue', localModelValue.value);
      emit('close');
    };

    return {
      localModelValue,
      isLocalModelValueInvalid,
      dismissChanges,
      saveChanges,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-nickname-modal {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $modalZIndex;
  pointer-events: all;

  &__frame {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 6px;
    cursor: default;
    width: 360px;
  }

  &__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    padding: 0 16px;
    height: 56px;
    border-bottom: 1px solid #F0F0F0;

    .icon {
      stroke: #000;
      cursor: pointer;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 16px;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-label {
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    height: 64px;
    padding: 0 16px;
    border-top: 1px solid #F0F0F0;
  }

  &__heading {
    font-size: 15.72px;
    font-family: 'Poppins';
    font-weight: 600;
  }
}
</style>
