<template>
  <div
    ref="rootElement"
    class="customer-profiling-details-info-strength-select"
  >
    <div
      :class="[
        'customer-profiling-details-info-strength-select__field',
        { 'customer-profiling-details-info-strength-select__field--is-active': selectedLabel },
      ]"
      @click.stop="toggleMenu"
    >
      <span
        :class="[
          'customer-profiling-details-info-strength-select__field-label',
          { 'customer-profiling-details-info-strength-select__field-label--is-placeholder': !selectedLabel }
        ]"
      >
        {{ selectedLabel }}
        <span>
          &minus;
          {{ selectedDescription }}
        </span>
      </span>
      <Icon
        class="customer-profiling-details-info-strength-select__field-icon"
        name="chevron-selector"
      />
    </div>
    <div
      v-if="isMenuOpen"
      class="customer-profiling-details-info-strength-select__menu"
    >
      <div
        v-for="option in strengthOptions"
        :key="option.id"
        class="customer-profiling-details-info-strength-select__item"
        @click.stop="setSelectedStrengthId(option.id)"
      >
        <div class="customer-profiling-details-info-strength-select__item-label">
          <span class="customer-profiling-details-info-strength-select__item-label-id">
            {{ option.id }}
          </span>
          <span class="customer-profiling-details-info-strength-select__item-label-text">
            &minus; {{ option.label }}
          </span>
        </div>
        <Icon
          v-if="selectedStrengthId === option.id"
          class="customer-profiling-details-info-strength-select__item-icon"
          name="check"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  emits: {
    'update:modelValue': {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);

    const isMenuOpen = ref(false);
    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const selectedStrengthId = computed(() => props.modelValue || 0);
    const strengthOptions = ref([
      {
        id: 1,
        label: 'Confirmed recreational',
      },
      {
        id: 2,
        label: 'Suspected recreational',
      },
      {
        id: 3,
        label: 'Neutral',
      },
      {
        id: 4,
        label: 'Suspected sharp',
      },
      {
        id: 5,
        label: 'Confirmed sharp',
      },
    ]);

    const selectedLabel = computed(() => {
      const id = Number(selectedStrengthId.value);
      const option = find(strengthOptions.value, { id });
      return Number(option?.id || '0');
    });
    const selectedDescription = computed(() => {
      const id = Number(selectedStrengthId.value);
      const option = find(strengthOptions.value, { id });
      return option?.label || 'not set';
    });

    const setSelectedStrengthId = (newSelectedStrengthId) => {
      emit('update:modelValue', newSelectedStrengthId);
      isMenuOpen.value = false;
    };

    onClickOutside(rootElement, () => {
      isMenuOpen.value = false;
    });

    return {
      rootElement,
      isMenuOpen,
      toggleMenu,
      selectedStrengthId,
      strengthOptions,
      selectedLabel,
      selectedDescription,
      setSelectedStrengthId,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-info-strength-select {
  position: relative;
  width: 100%;

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 0 8px;
    cursor: pointer;
    color: #191414;
    border: 1px solid #f0f0f0;
    height: 32px;
    border-radius: 4px;
    overflow: hidden;
    min-height: 32px;
    max-height: 32px;
    width: 100%;

    &:hover {
      background-color: #FAFAFA;
      border-color: #DDDDDD;
    }

    &--is-active {
      border-color: #DDDDDD;
    }

    &-label {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #191414;

      span {
        color: #6D6D6D;
      }

      &--is-placeholder {
        color: #CDCDCD;

        span {
          color: #CDCDCD;
        }
      }
    }

    &-icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    max-height: 230px;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #f0f0f0);
    background: var(--neutral-bg-default-resting, #fff);
    box-shadow: 0 2px 4px 0 rgba(25, 20, 20, .08);
    margin-top: 4px;
    padding: 4px 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    height: 32px;
    padding: 8px;

    &:hover {
      background-color: #fafafa;
      outline: none;
    }

    &-label {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;

      &-id {
        color: #191414;
      }

      &-text {
        color: #6D6D6D;
      }
    }

    &-icon {
      width: 16px;
      height: 16px;
      stroke: #191414;
    }
  }
}
</style>
