<template>
  <div
    :class="[
      'customer-profiling-details-sidebar-notes-input',
      { 'customer-profiling-details-sidebar-notes-input--is-processing': isProcessing },
    ]"
    :style="{ height: `${height}px` }"
    @mousemove="dragResize"
    @mouseup="stopResize"
    @mouseleave="stopResize"
  >
    <textarea
      ref="textareaElement"
      class="customer-profiling-details-sidebar-notes-input__content"
      v-model="localModelValue"
      :readonly="isProcessing"
      :placeholder="placeholder"
    />
    <div class="customer-profiling-details-sidebar-notes-input__footer">
      <Button
        v-if="isCancelable"
        variant="tertiary"
        :disabled="isProcessing"
        small
        @click="cancelChanges"
      >
        Cancel
        <Icon
          name="x"
        />
      </Button>
      <Button
        variant="primary"
        :disabled="isSubmitDisabled"
        :loading="isProcessing"
        small
        @click="submitChanges"
      >
        {{ isProcessing ? 'Sending...' : 'Send' }}
        <Icon
          v-if="!isProcessing"
          name="chevron-right"
        />
      </Button>
    </div>
    <svg
      class="customer-profiling-details-sidebar-notes-input__grip"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
      @mousedown="startResize"
      tabindex="0"
    >
      <path
        d="M2.65051 0.861278C2.39016 0.600928 1.96805 0.600928 1.7077 0.861278C1.44735 1.12163 1.44735 1.54374 1.7077 1.80409L10.193 10.2894C10.4533 10.5497 10.8754 10.5497 11.1358 10.2894C11.3961 10.029 11.3961 9.60691 11.1358 9.34656L2.65051 0.861278Z"
        fill="#A9A9A9"
      />
      <path
        d="M1.90295 4.43729C1.6426 4.17694 1.22049 4.17694 0.960144 4.43729C0.699794 4.69764 0.699794 5.11975 0.960144 5.3801L6.617 11.037C6.87735 11.2973 7.29946 11.2973 7.55981 11.037C7.82016 10.7766 7.82016 10.3545 7.55981 10.0941L1.90295 4.43729Z"
        fill="#A9A9A9"
      />
    </svg>
  </div>
</template>

<script>
import { cloneDeep, isEqual, trim } from 'lodash';
import {
  ref,
  computed,
  watch,
  onMounted,
} from 'vue';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';

export default {
  components: {
    Icon,
    Button,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    isCancelable: {
      type: Boolean,
      default: false,
    },
    isAutofocused: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
    cancel: {
      type: undefined,
    },
  },
  setup(props, { emit }) {
    const textareaElement = ref();
    const localModelValue = ref('');
    const isResizing = ref(false);
    const initialY = ref(0);
    const height = ref(95);

    const isContentEmpty = computed(() => !trim(localModelValue.value || '')?.length);
    const hasContentChanged = computed(() => !isEqual(props.modelValue, localModelValue.value));
    const isSubmitDisabled = computed(() => isContentEmpty.value || !hasContentChanged.value);

    const cancelChanges = () => {
      emit('cancel');
    };
    const submitChanges = () => {
      emit('update:modelValue', localModelValue.value);
    };
    const startResize = (event) => {
      isResizing.value = true;
      initialY.value = event.clientY;
    };
    const dragResize = (event) => {
      if (!isResizing.value) return;
      const newHeight = height.value + (event.clientY - initialY.value);
      height.value = newHeight < 95 ? 95 : newHeight;
      initialY.value = event.clientY;
    };
    const stopResize = () => {
      isResizing.value = false;
    };
    watch(
      () => props.modelValue,
      (newLocalModelValue) => {
        localModelValue.value = cloneDeep(newLocalModelValue);
      },
      { immediate: true },
    );

    onMounted(() => {
      if (!props.isAutofocused || !textareaElement.value) return;
      textareaElement.value.focus();
    });

    return {
      textareaElement,
      localModelValue,
      height,
      isContentEmpty,
      hasContentChanged,
      isSubmitDisabled,
      cancelChanges,
      submitChanges,
      startResize,
      dragResize,
      stopResize,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-sidebar-notes-input {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  width: 100%;

  &__content {
    width: 100%;
    height: 100%;
    resize: none;
    padding-bottom: 28px;

    &:focus {
      outline: none;
    }
  }

  &__footer {
    display: none;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    border-top: 1px solid #F0F0F0;

    .button {
      text-align: center;
      justify-content: center;
      width: 96px;
      gap: 4px;
    }
  }

  &__grip {
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    padding: 4px 4px 0 0;
    cursor: s-resize;

    &:focus {
      outline: none;
    }
  }

  &:focus-within {
    padding-bottom: 0;

    .customer-profiling-details-sidebar-notes-input {
      &__content {
        padding-bottom: 0;
      }

      &__footer {
        display: flex;
      }
    }
  }

  &--is-processing {
    padding-bottom: 0;

    .customer-profiling-details-sidebar-notes-input {
      &__content {
        padding-bottom: 0;
      }

      &__footer {
        display: flex;
      }
    }
  }
}
</style>
