import { parseISO, subMinutes } from 'date-fns';

export const parseDateTime = (dateTime) => {
  const parsedDateTime = parseISO(dateTime);
  const timezoneOffset = new Date().getTimezoneOffset();
  return subMinutes(parsedDateTime, timezoneOffset);
};

export const formatDateTimeAgo = (dateTime, now = new Date()) => {
  const seconds = Math.floor((now - parseDateTime(dateTime)) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) return `${Math.floor(interval)}y ago`;
  interval = seconds / 2592000;
  if (interval > 1) return `${Math.floor(interval)}m ago`;
  interval = seconds / 86400;
  if (interval > 1) return `${Math.floor(interval)}d ago`;
  interval = seconds / 3600;
  if (interval > 1) return `${Math.floor(interval)}h ago`;
  interval = seconds / 60;
  if (interval > 1) return `${Math.floor(interval)}min ago`;
  return 'just now';
};
