<template>
  <div class="customer-profiling-details-sidebar-notes-sorter">
    <span
      class="customer-profiling-details-sidebar-notes-sorter__label"
      @click="toggleSort"
    >
      Sort by: {{ sortLabel }}
    </span>
    <Icon
      class="customer-profiling-details-sidebar-notes-sorter__icon"
      :name="sortIcon"
      @click="toggleSort"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const sortLabel = computed(() => {
      switch (props.modelValue) {
      case 'CREATED_AT_ASC':
        return 'Oldest first';
      case 'CREATED_AT_DESC':
        return 'Newest first';
      default:
        return 'N/A';
      }
    });
    const sortIcon = computed(() => {
      switch (props.modelValue) {
      case 'CREATED_AT_ASC':
        return 'arrow-up';
      case 'CREATED_AT_DESC':
        return 'arrow-down';
      default:
        return '';
      }
    });

    const toggleSort = () => {
      switch (props.modelValue) {
      case 'CREATED_AT_ASC':
        emit('update:modelValue', 'CREATED_AT_DESC');
        break;
      case 'CREATED_AT_DESC':
        emit('update:modelValue', 'CREATED_AT_ASC');
        break;
      default:
        // noop
      }
    };

    return {
      sortLabel,
      sortIcon,
      toggleSort,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-sidebar-notes-sorter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  user-select: none;

  &__label,
  &__icon {
    cursor: pointer;
  }

  &__label {
    color: #6D6D6D;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 11.85px;
    text-transform: uppercase;
  }

  &__icon {
    padding: 4px 8px;
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;

    svg {
      path {
        fill: #6D6D6D;
      }
    }
  }
}
</style>
