<template>
  <div class="customer-profiling-details-sidebar-notes">
    <div
      v-if="isInitializing"
      class="customer-profiling-details-sidebar-notes__content"
    >
      <Spinner />
    </div>
    <div
      v-else
      class="customer-profiling-details-sidebar-notes__content"
    >
      <div class="customer-profiling-details-sidebar-notes__content-header">
        <CustomerProfilingDetailsSidebarNotesSorter
          :model-value="orderBy"
          @update:model-value="setOrderBy"
        />
      </div>
      <div class="customer-profiling-details-sidebar-notes__content-body">
        <CustomerProfilingDetailsSidebarNotesComment
          v-for="comment in allComments"
          :key="comment.id"
          :model-value="comment"
          @update:model-value="updateComment"
        />
        <Button
          v-if="!isLoading && hasMoreComments"
          variant="tertiary"
          icon="rotate-ccw"
          @click="loadMoreComments"
        >
          Load more comments
        </Button>
      </div>
      <div class="customer-profiling-details-sidebar-notes__content-footer">
        <CustomerProfilingDetailsSidebarNotesInput
          :model-value="commentContent"
          :is-processing="isProcessingComment"
          placeholder="Add a note"
          @update:model-value="submitComment"
        />
      </div>
      <div
        v-if="isLoading"
        class="customer-profiling-details-sidebar-notes__content-loader"
      >
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script>
import { map } from 'lodash';
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import * as api from '@/services/api';
import Spinner from '@/components/common/Spinner';
import Button from '@/components/common/Button';
import CustomerProfilingDetailsSidebarNotesSorter from './CustomerProfilingDetailsSidebarNotesSorter';
import CustomerProfilingDetailsSidebarNotesComment from './CustomerProfilingDetailsSidebarNotesComment';
import CustomerProfilingDetailsSidebarNotesInput from './CustomerProfilingDetailsSidebarNotesInput';

export default {
  components: {
    Spinner,
    Button,
    CustomerProfilingDetailsSidebarNotesSorter,
    CustomerProfilingDetailsSidebarNotesComment,
    CustomerProfilingDetailsSidebarNotesInput,
  },
  setup() {
    const store = useStore();

    const commentContent = ref('');
    const isProcessingComment = ref(false);

    const customerId = computed(() => store.getters['betTicker/customerKey']?.id || '');
    const customerOperator = computed(() => store.getters['betTicker/customerKey']?.operator || '');
    const isInitializing = computed(() => store.getters['betTicker/customerNotesIsInitializing']);
    const isLoading = computed(() => store.getters['betTicker/customerNotesIsLoading']);
    const orderBy = computed(() => store.getters['betTicker/customerNotesOrderBy']);
    const first = computed(() => store.getters['betTicker/customerNotesFirst']);
    const totalComments = computed(() => store.getters['betTicker/customerNotesTotalCount']);
    const allComments = computed(() => store.getters['betTicker/customerNotesData']);
    const hasMoreComments = computed(() => store.getters['betTicker/customerNotesHasMore']);

    const setOrderBy = (newOrderBy) => store.dispatch('betTicker/loadCustomerNotes', {
      orderBy: newOrderBy,
      first: 5,
    });
    const updateComment = (updatedComment) => {
      const updatedAllComments = map(allComments.value, (comment) => (updatedComment.id === comment.id ? updatedComment : comment));
      store.dispatch('betTicker/setCustomerNotesData', updatedAllComments);
      store.dispatch('betTicker/reloadCustomerNotes');
    };
    const loadMoreComments = () => store.dispatch('betTicker/loadCustomerNotes', {
      orderBy: orderBy.value,
      first: first.value + 5,
    });
    const submitComment = async (newCommentContent) => {
      try {
        isProcessingComment.value = true;
        commentContent.value = newCommentContent;
        await api.addComment({
          accountId: customerId.value,
          operatorId: customerOperator.value,
          content: newCommentContent,
        });
        await store.dispatch('betTicker/reloadCustomerNotes');
      } catch (error) {
        console.error(error);
        store.dispatch('addNotification', {
          message: 'Cannot submit a new comment. Please try again later.',
          type: 'error',
          duration: 5000,
        });
      } finally {
        isProcessingComment.value = false;
        commentContent.value = '';
      }
    };

    onMounted(() => {
      store.dispatch('betTicker/initCustomerNotes');
    });
    onBeforeUnmount(() => {
      store.dispatch('betTicker/clearCustomerNotes');
    });

    return {
      isInitializing,
      isLoading,
      orderBy,
      first,
      totalComments,
      allComments,
      commentContent,
      isProcessingComment,
      hasMoreComments,
      setOrderBy,
      updateComment,
      loadMoreComments,
      submitComment,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-sidebar-notes {
  width: 100%;
  height: 100%;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &-header {
      width: 100%;
      padding: 16px 16px 0;
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      height: 100%;
      padding: 8px 16px;
      overflow-y: auto;
    }

    &-footer {
      width: 100%;
      padding: 0 16px 16px;
    }

    &-loader {
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
