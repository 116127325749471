<template>
  <div class="customer-profiling-details-sidebar-notes-comment">
    <CustomerProfilingDetailsSidebarNotesCard
      :model-value="modelValue"
      :is-loading="isProcessing"
      v-model:is-expanded="isExpanded"
      @update:model-value="editComment"
      @remove="removeComment"
      @reply="replyToComment"
    />
    <div
      v-if="isExpanded"
      class="customer-profiling-details-sidebar-notes-comment__activity"
    >
      <div
        v-if="isInitializing"
        class="customer-profiling-details-sidebar-notes-comment__activity-loader"
      >
        <Spinner
          small
        />
      </div>
      <div
        v-else
        class="customer-profiling-details-sidebar-notes-comment__activity-list"
      >
        <Button
          v-if="hasMoreReplies"
          class="customer-profiling-details-sidebar-notes-comment__activity-list-button"
          variant="secondary"
          icon="rotate-ccw"
          :loading="isLoading"
          @click="loadMoreThreads"
        >
          {{ isLoading ? 'Loading replies...' : 'Load more replies' }}
        </Button>
        <CustomerProfilingDetailsSidebarNotesCard
          v-for="thread in allThreads"
          :key="thread.id"
          :model-value="thread"
          :is-loading="isProcessingThread[thread.id]"
          @update:model-value="editThread"
          @remove="removeThread(thread)"
          @reply="replyToComment"
        />
        <CustomerProfilingDetailsSidebarNotesInput
          v-if="isThreadToggled"
          :model-value="threadContent"
          :is-processing="isProcessingNewThread"
          placeholder="Reply..."
          is-autofocused
          is-cancelable
          @update:model-value="submitThread"
          @cancel="cancelThread"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  cloneDeep,
  filter,
  map,
  reverse,
} from 'lodash';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import * as api from '@/services/api';
import Spinner from '@/components/common/Spinner';
import Button from '@/components/common/Button';
import CustomerProfilingDetailsSidebarNotesCard from './CustomerProfilingDetailsSidebarNotesCard';
import CustomerProfilingDetailsSidebarNotesInput from './CustomerProfilingDetailsSidebarNotesInput';

const THREAD_LIMIT = 5;

export default {
  components: {
    Spinner,
    Button,
    CustomerProfilingDetailsSidebarNotesCard,
    CustomerProfilingDetailsSidebarNotesInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: {
    'update:modelValue': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const isProcessing = ref(false);
    const isExpanded = ref(false);
    const isInitializing = ref(false);
    const isLoading = ref(false);
    const first = ref(THREAD_LIMIT);
    const totalThreads = ref(0);
    const allThreads = ref([]);
    const threadContent = ref('');
    const isThreadToggled = ref(false);
    const isProcessingThread = ref({});
    const isProcessingNewThread = ref(false);

    const customerOperator = computed(() => store.getters['betTicker/customerKey']?.operator || '');
    const hasMoreReplies = computed(() => allThreads.value.length < totalThreads.value);

    const editComment = async (editedComment) => {
      const oldComment = cloneDeep(props.modelValue);
      try {
        isProcessing.value = true;
        emit('update:modelValue', editedComment);
        await api.editComment({
          commentId: editedComment.id,
          content: editedComment.content,
        });
        store.dispatch('betTicker/reloadCustomerNotes');
      } catch (error) {
        console.error(error);
        store.dispatch('addNotification', {
          message: 'Cannot edit the comment. Please try again later.',
          type: 'error',
          duration: 5000,
        });
        emit('update:modelValue', oldComment);
      } finally {
        isProcessing.value = false;
      }
    };
    const removeComment = async () => {
      const oldAllComments = cloneDeep(store.getters['betTicker/customerNotesData']);
      try {
        store.dispatch('betTicker/setCustomerNotesLoading', true);
        const newAllComments = filter(store.getters['betTicker/customerNotesData'], (comment) => props.modelValue.id !== comment.id);
        store.dispatch('betTicker/setCustomerNotesData', newAllComments);
        await api.deleteComment({
          commentId: props.modelValue.id,
        });
        await store.dispatch('betTicker/reloadCustomerNotes');
      } catch (error) {
        console.error(error);
        store.dispatch('addNotification', {
          message: 'Cannot delete the comment. Please try again later.',
          type: 'error',
          duration: 5000,
        });
        store.dispatch('betTicker/setCustomerNotesData', oldAllComments);
      } finally {
        store.dispatch('betTicker/setCustomerNotesLoading', false);
      }
    };
    const replyToComment = () => {
      isExpanded.value = true;
      isThreadToggled.value = true;
    };
    const loadThreads = async (options) => {
      try {
        if (!options?.covert) isLoading.value = true;
        first.value = options?.first || THREAD_LIMIT;
        const response = await api.useCustomerRepliesQuery({
          id: props.modelValue.id,
          first: first.value,
          offset: 0,
        });
        totalThreads.value = response.data?.comment?.replies?.totalCount || 0;
        allThreads.value = reverse([...(response.data?.comment?.replies?.nodes || [])]);
      } catch (error) {
        console.error(error);
        store.dispatch('addNotification', {
          message: 'Could not load threads. Please try again later.',
          type: 'error',
          duration: 5000,
        });
      } finally {
        isInitializing.value = false;
        isLoading.value = false;
      }
    };
    const loadMoreThreads = () => loadThreads({
      first: first.value + THREAD_LIMIT,
    });
    const reloadThreads = (options) => loadThreads({
      first: first.value,
      covert: options?.covert,
    });
    const editThread = async (editedThread) => {
      const oldAllThreads = cloneDeep(allThreads.value);
      try {
        store.dispatch('betTicker/setCustomerNotesLoading', true);
        isProcessingThread.value[editedThread.id] = true;
        allThreads.value = map(allThreads.value, (thread) => (editedThread.id === thread.id ? editedThread : thread));
        await api.editThread({
          threadId: editedThread.id,
          content: editedThread.content,
        });
        await reloadThreads({
          covert: true,
        });
        await store.dispatch('betTicker/reloadCustomerNotes');
      } catch (error) {
        console.error(error);
        store.dispatch('addNotification', {
          message: 'Cannot edit the thread. Please try again later.',
          type: 'error',
          duration: 5000,
        });
        allThreads.value = oldAllThreads;
      } finally {
        store.dispatch('betTicker/setCustomerNotesLoading', false);
        isProcessingThread.value[editedThread.id] = false;
      }
    };
    const removeThread = async (threadToRemove) => {
      const oldAllThreads = cloneDeep(allThreads.value);
      try {
        store.dispatch('betTicker/setCustomerNotesLoading', true);
        allThreads.value = filter(allThreads.value, (thread) => threadToRemove.id !== thread.id);
        totalThreads.value -= 1;
        await api.deleteThread({
          threadId: threadToRemove.id,
        });
        await reloadThreads();
        await store.dispatch('betTicker/reloadCustomerNotes');
      } catch (error) {
        console.error(error);
        store.dispatch('addNotification', {
          message: 'Cannot delete the thread. Please try again later.',
          type: 'error',
          duration: 5000,
        });
        allThreads.value = oldAllThreads;
      } finally {
        store.dispatch('betTicker/setCustomerNotesLoading', false);
      }
    };
    const submitThread = async (newThreadContent) => {
      try {
        isProcessingNewThread.value = true;
        threadContent.value = newThreadContent;
        await api.addThread({
          commentId: props.modelValue.id,
          operatorId: customerOperator.value,
          content: newThreadContent,
        });
        await reloadThreads({
          covert: true,
        });
        isThreadToggled.value = false;
        await store.dispatch('betTicker/reloadCustomerNotes');
        threadContent.value = '';
      } catch (error) {
        console.error(error);
        store.dispatch('addNotification', {
          message: 'Cannot submit a new reply. Please try again later.',
          type: 'error',
          duration: 5000,
        });
      } finally {
        isProcessingNewThread.value = false;
        isThreadToggled.value = false;
      }
    };
    const cancelThread = () => {
      threadContent.value = '';
      isThreadToggled.value = false;
      if (totalThreads.value.length < 1) {
        isExpanded.value = false;
      }
    };

    watch(
      isExpanded,
      (newIsExpanded) => {
        if (newIsExpanded) {
          if (!props.modelValue.replies?.totalCount) return;
          isInitializing.value = true;
          loadThreads({ first: 1 });
        } else {
          isThreadToggled.value = false;
          totalThreads.value = 0;
          allThreads.value = [];
        }
      },
    );

    return {
      isProcessing,
      isExpanded,
      isInitializing,
      isLoading,
      first,
      totalThreads,
      allThreads,
      threadContent,
      isThreadToggled,
      isProcessingThread,
      isProcessingNewThread,
      hasMoreReplies,
      editComment,
      removeComment,
      replyToComment,
      loadThreads,
      loadMoreThreads,
      editThread,
      removeThread,
      submitThread,
      cancelThread,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-sidebar-notes-comment {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  &__activity {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 12px 0;
      width: 100%;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0 0 0 28px;
      width: 100%;

      &-button {
        .icon {
          svg {
            path {
              stroke: #191414;
            }
          }
        }
      }

      & > * {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -14px;
          left: -14.5px;
          width: 14.5px;
          height: 30.5px;
          border-left: 1px solid #F0F0F0;
          border-bottom: 1px solid #F0F0F0;
          border-bottom-left-radius: 8px;
        }

        &:not(:last-child)::after {
          content: '';
          position: absolute;
          top: 0;
          left: -14.5px;
          width: 14.5px;
          height: calc(100% + 16px);
          border-left: 1px solid #F0F0F0;
        }
      }
    }
  }
}
</style>
