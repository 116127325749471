<template>
  <div class="customer-profiling-details-statistics-infographic">
    <div class="customer-profiling-details-statistics-infographic__header">
      <span class="customer-profiling-details-statistics-infographic__title">
        {{ title }}
      </span>
    </div>
    <Doughnut
      :key="JSON.stringify({ ...modelValue, ...chartOptions })"
      :class="[
        'customer-profiling-details-statistics-infographic__content',
        `customer-profiling-details-statistics-infographic__content--is-${modelValue.id}`
      ]"
      :chart-data="modelValue"
      :chart-options="chartOptions"
    />
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { Doughnut } from 'vue-chartjs';
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

Chart.register(Title, Tooltip, Legend, ArcElement);

export default {
  components: {
    Doughnut,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: 'Infographic',
    },
  },
  setup() {
    const chartOptions = ref({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
          align: 'center',
          labels: {
            boxWidth: 6,
            boxHeight: 6,
            usePointStyle: true,
            pointStyle: 'circle',
            generateLabels(chart) {
              const { datasets } = chart.data;
              return datasets[0].data
                .map((data, i) => ({
                  text: `${chart.data.labels[i]} ${data}%`,
                  fillStyle: datasets[0].backgroundColor[i],
                  index: i,
                }))
                .filter((label) => !label.text.trim().includes('BG'));
            },
          },
          onClick() {
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      interaction: {
        mode: 'none',
      },
    });

    const updateLegendPosition = () => {
      if (window.innerWidth < 1550) {
        chartOptions.value.plugins.legend.position = 'bottom';
        chartOptions.value.plugins.legend.align = 'start';
        return;
      }
      chartOptions.value.plugins.legend.position = 'right';
    };

    onMounted(() => {
      window.addEventListener('resize', updateLegendPosition);
      updateLegendPosition();
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateLegendPosition);
    });

    return {
      chartOptions,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-statistics-infographic {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 10px;
    line-height: 11.85px;
    text-transform: uppercase;
    color: #6D6D6D;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 11px;
  }

  &__content {
    width: 130px;

    &--is-sport {
      height: 196px;
    }

    &--is-bet-types {
      height: 173px;
    }

    &--is-event-state {
      height: 151px;
    }

    @media screen and (min-width: 1550px) {
      width: 260px;
      height: 100px;
    }
  }
}
</style>
