<template>
  <div class="customer-profiling-details-sidebar-notes-card">
    <div class="customer-profiling-details-sidebar-notes-card__info">
      <span class="customer-profiling-details-sidebar-notes-card__info-username">
        {{ username }}
      </span>
      <span class="customer-profiling-details-sidebar-notes-card__info-operator">
        {{ operator }}
      </span>
      <span class="customer-profiling-details-sidebar-notes-card__info-timestamp">
        {{ timestamp }}
      </span>
    </div>
    <div class="customer-profiling-details-sidebar-notes-card__action">
      <Spinner
        v-if="isLoading"
        small
      />
      <IconButton
        v-else-if="!isAuthor"
        icon="reply"
        size="small"
        @click="reply"
      />
      <div
        v-else
        ref="actionDropdownElement"
        class="customer-profiling-details-sidebar-notes-card__action-dropdown"
      >
        <IconButton
          icon="dots-vertical"
          size="small"
          @click="toggleActionDropdown"
        />
        <div
          v-if="isActionDropdownOpen"
          class="customer-profiling-details-sidebar-notes-card__action-dropdown-menu"
        >
          <span
            class="customer-profiling-details-sidebar-notes-card__action-dropdown-item"
            @click="reply"
          >
            <Icon
              name="reply"
            />
            Reply
          </span>
          <span
            class="customer-profiling-details-sidebar-notes-card__action-dropdown-item"
            @click="setEditMode"
          >
            <Icon
              name="pencil"
            />
            Edit
          </span>
          <span
            class="customer-profiling-details-sidebar-notes-card__action-dropdown-item customer-profiling-details-sidebar-notes-card__action-dropdown-item--is-danger"
            @click="remove"
          >
            <Icon
              name="trash"
            />
            Delete
          </span>
        </div>
      </div>
    </div>
    <div class="customer-profiling-details-sidebar-notes-card__message">
      <CustomerProfilingDetailsSidebarNotesInput
        v-if="isEditMode"
        :model-value="modelValue.content"
        is-cancelable
        is-autofocused
        @update:model-value="edit"
        @cancel="isEditMode = false"
      />
      <span
        v-else
        class="customer-profiling-details-sidebar-notes-card__message-content"
      >
        {{ modelValue.content || '' }}
      </span>
    </div>
    <div
      v-if="isActivityDisplayed"
      class="customer-profiling-details-sidebar-notes-card__activity"
      @click="toggleActivity"
    >
      <span class="customer-profiling-details-sidebar-notes-card__activity-label">
        {{ activityLabel }}
      </span>
      <Icon
        :name="activityIcon"
      />
    </div>
  </div>
</template>

<script>
import { isNil, split } from 'lodash';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { formatDateTimeAgo } from '@/services/helpers/datetime';
import { parseUserPrincipal } from '@/services/helpers/customer-profiling';
import Spinner from '@/components/common/Spinner';
import Icon from '@/components/common/Icon';
import IconButton from '@/components/common/IconButton';
import CustomerProfilingDetailsSidebarNotesInput from './CustomerProfilingDetailsSidebarNotesInput';

export default {
  components: {
    Spinner,
    Icon,
    IconButton,
    CustomerProfilingDetailsSidebarNotesInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': {
      type: Object,
    },
    remove: {
      type: undefined,
    },
    reply: {
      type: undefined,
    },
    'update:isExpanded': {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const userPrincipal = parseUserPrincipal(localStorage.getItem('userToken'));

    const actionDropdownElement = ref();
    const isActionDropdownOpen = ref(false);
    const isEditMode = ref(false);

    const isAuthor = computed(() => props.modelValue.createdBy === userPrincipal);
    const isActivitySupported = computed(() => !isNil(props.modelValue.replies));
    const totalActivityCount = computed(() => props.modelValue.replies?.totalCount || 0);
    const activityLabel = computed(() => {
      if (totalActivityCount.value === 1) return `${totalActivityCount.value} Reply`;
      return `${totalActivityCount.value} Replies`;
    });
    const isActivityDisplayed = computed(() => isActivitySupported.value && totalActivityCount.value);
    const activityIcon = computed(() => (props.isExpanded ? 'chevron-up' : 'chevron-down'));
    const username = computed(() => {
      const parts = split(props.modelValue.createdBy || '', '/');
      const idPart = parts?.[2] || '';
      const usernamePart = parts?.[3] || '';
      return usernamePart || idPart || 'N/A';
    });
    const operator = computed(() => {
      const parts = split(props.modelValue.createdBy || '', '/');
      const operatorPart = parts?.[1] || 'N/A';
      return `(${operatorPart})`;
    });
    const timestamp = computed(() => {
      const formattedCreatedAt = formatDateTimeAgo(props.modelValue.createdAt);
      if (!props.modelValue.edited) return `Posted ${formattedCreatedAt}`;
      const formattedUpdatedAt = formatDateTimeAgo(props.modelValue.updatedAt);
      return `Posted ${formattedCreatedAt} • Edited ${formattedUpdatedAt}`;
    });

    const toggleActionDropdown = () => {
      isActionDropdownOpen.value = !isActionDropdownOpen.value;
    };
    const setEditMode = () => {
      isEditMode.value = true;
      isActionDropdownOpen.value = false;
    };
    const edit = (newContent) => {
      emit('update:modelValue', {
        ...props.modelValue,
        updatedAt: new Date().toUTCString(),
        content: newContent,
      });
      isEditMode.value = false;
    };
    const remove = () => {
      emit('remove');
      isActionDropdownOpen.value = false;
    };
    const reply = () => {
      emit('reply');
      isActionDropdownOpen.value = false;
    };
    const toggleActivity = () => {
      emit('update:isExpanded', !props.isExpanded);
    };

    onClickOutside(actionDropdownElement, () => {
      isActionDropdownOpen.value = false;
    });

    return {
      actionDropdownElement,
      isActionDropdownOpen,
      isEditMode,
      isAuthor,
      isActivityDisplayed,
      activityLabel,
      activityIcon,
      username,
      operator,
      timestamp,
      toggleActionDropdown,
      setEditMode,
      edit,
      remove,
      reply,
      toggleActivity,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-sidebar-notes-card {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  background-color: #fff;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  padding: 12px 12px 0;

  &__info {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 4px;
    width: 100%;
    height: 34px;
    min-height: 34px;
    max-height: 34px;
    overflow: hidden;

    &-username {
      color: #191414;
      font-family: 'Rubik', sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-operator {
      color: #6D6D6D;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;
    }

    &-timestamp {
      grid-column: 1 / 3;
      color: #6D6D6D;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 14.22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;    }
  }

  &__action {
    &-dropdown {
      position: relative;

      &-menu {
        background-color: #fff;
        border: 1px solid #F0F0F0;
        box-shadow: 0px 2px 4px 0px #19141414;
        border-radius: 4px;
        position: absolute;
        top: 100%;
        right: 0;
        width: 180px;
        min-height: 16px;
        margin-top: 4px;
        z-index: 9000;
        padding: 4px 0;
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 100%;
        height: 32px;
        min-height: 32px;
        max-height: 32px;
        padding: 0 8px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: #FAFAFA;
        }

        &--is-danger {
          color: #ff2e2d;

          .icon {
            svg {
              path {
                fill: #ff2e2d;
              }
            }
          }
        }
      }
    }
  }

  &__message {
    grid-column: 1 / 3;
    padding: 12px 0;

    &-content {
      word-break: break-word;
    }
  }

  &__activity {
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 12px;
    margin: 0 -12px;
    border-top: 1px solid #F0F0F0;
    width: calc(100% + 24px);
    cursor: pointer;
    user-select: none;
    background-color: #fff;
    position: relative;
    z-index: 1;

    &:hover {
      background-color: #FAFAFA;
    }

    &:active {
      background-color: #F0F0F0;
    }

    &-label {
      display: block;
      color: #6d6d6d;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 14.22px;
    }

    .icon {
      stroke: #6d6d6d;
    }
  }
}
</style>
