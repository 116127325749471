<template>
  <transition name="slide-left">
    <div
      v-if="sidebar"
      class="customer-profiling-details-sidebar"
    >
      <div class="customer-profiling-details-sidebar__header">
        <span class="customer-profiling-details-sidebar__header-title">
          {{ title }}
        </span>
        <IconButton
          class="customer-profiling-details-sidebar__header-button"
          icon="x"
          @click="closeSidebar"
        />
      </div>
      <component
        v-if="component"
        :is="component"
        class="customer-profiling-details-sidebar__content"
      />
    </div>
  </transition>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { CustomerDetailsSidebar } from '@/services/helpers/customer-profiling';
import IconButton from '@/components/common/IconButton';
import CustomerProfilingDetailsSidebarFilters from './CustomerProfilingDetailsSidebarFilters';
import CustomerProfilingDetailsSidebarNotes from './CustomerProfilingDetailsSidebarNotes';

export default {
  components: {
    IconButton,
    CustomerProfilingDetailsSidebarFilters,
    CustomerProfilingDetailsSidebarNotes,
  },
  setup() {
    const store = useStore();

    const sidebar = computed(() => store.getters['betTicker/customerSidebar']);
    const title = computed(() => {
      switch (sidebar.value) {
      case CustomerDetailsSidebar.FILTERS:
        return 'Filters';
      case CustomerDetailsSidebar.NOTES:
        return 'Customer notes';
      default:
        return '';
      }
    });
    const component = computed(() => {
      switch (sidebar.value) {
      case CustomerDetailsSidebar.FILTERS:
        return 'CustomerProfilingDetailsSidebarFilters';
      case CustomerDetailsSidebar.NOTES:
        return 'CustomerProfilingDetailsSidebarNotes';
      default:
        return '';
      }
    });

    const closeSidebar = () => {
      store.dispatch('betTicker/setCustomerSidebar', '');
    };

    return {
      sidebar,
      title,
      component,
      closeSidebar,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-sidebar {
  background-color: #fff;
  position: fixed;
  top: 69px;
  right: 0;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  height: calc(100% - 69px);
  border-left: 1px solid #F0F0F0;
  box-shadow: 0px 2px 4px 0px #19141414;
  z-index: $betTickerSidebarZIndex;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    padding: 0 16px;
    border-bottom: 1px solid #F0F0F0;

    &-title {
      display: block;
      color: #191414;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 15.72px;
      line-height: 23.58px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-button {
      stroke: #191414;
    }
  }

  &__content {
    width: 100%;
    height: calc(100% - 64px);
  }
}
</style>
